import React from 'react';
import styled from 'styled-components';

import LandingContent from '../../components/LandingContent';
import colors from '../../utils/theme';
import { Modal } from 'semantic-ui-react';

import img1 from '../../images/Special Offers/HOTEL PICS (17).png';
import img2 from '../../images/Special Offers/EDUCATIONAL EXCURSIONS.png';
import img3 from '../../images/Special Offers/CORPORATE OFFSITES.png';

import img4 from '../../images/Special Offers/1.jpg';
import img5 from '../../images/Special Offers/2.jpg';
import img6 from '../../images/Special Offers/3.jpg';
import img7 from '../../images/Special Offers/4.jpg';
import img8 from '../../images/Special Offers/5.jpg';
import img9 from '../../images/Special Offers/6.jpg';

import Footer from '../../components/Footer';

// const icon1 = require('../../images/media/IMG_0383.png');
// const icon2 = require('../../images/media/IMG_0384 (2).png');
const icon1 = require('../../images/happening/p.jpg');
const icon2 = require('../../images/popup/s.png');
const icon3 = require('../../images/happening/risaa.png');
const icon4 = require('../../images/happening/HOLI.png');
const icon5 = require('../../images/happening/monsoon.jpg');
const icon6 = require('../../images/happening/page-0001.jpg');
const icon7 = require('../../images/happening/page-0002.jpg');
const icon8 = require('../../images/happening/cool.jpg');


const Happening = () => {
  return (
    <React.Fragment>
      <LandingContent images={[img4, img5, img6, img7, img8, img9]} />
      <Container>
        
        <SmallImages>
          <Modal dimmer={"blurring"} trigger={<Image src={icon2} />} style={{maxWidth: '700px'}} closeIcon>
            <Modal.Content>
              <StyledModalImage src={icon2} />
            </Modal.Content>
          </Modal>
          
          <Modal dimmer={"blurring"} trigger={<Image src={icon1} />} style={{maxWidth: '700px'}} closeIcon>
            <Modal.Content>
              <StyledModalImage src={icon1} />
            </Modal.Content>
          </Modal>
          
        </SmallImages>

        
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default Happening;

const Container = styled.div`
  color: ${colors.primary};
  padding: 3rem 2rem;
  display: grid;
  grid-row-gap: 5rem;
`;

const SmallImages = styled.div`
  display: flex;
  margin-left: 10vw;
  margin-top: 20px;
`;

const Image = styled.img`
  max-height: 300px;
  margin-right: 2rem;
  cursor: pointer;
  &:hover {
    filter: blur(1px);
  }
`;

const StyledModalImage = styled.img`
  width: 100%;
`;